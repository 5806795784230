<template>
    <div id="thepayone" class="thepayone">
    </div>
</template>
<script>
import api from "@/api";

export default {
    props: {
        order: Object,
    },
    data() {
        var { orderNo } = this.order;

        return {
            show: false,
            orderNo,
        }
    },
    mounted() {
    },
    methods: {
        async pay() {
            await api.plugins.thepayone.request({ params: { orderNo: this.orderNo } });
            alert("결제 링크가 발송 되었습니다");
        },
    },
};
</script>