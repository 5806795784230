<template>
    <component v-if="order" ref="pg" :is="pg" :order="order" @complete="complete" />
</template>

<script>
import Billgate from "@/components/plugins/galaxia/billgate-pay.vue";
import Lumenpay from '@/components/plugins/lumen/lumen-pay.vue';
import Coampay from '@/components/plugins/coampay/coam-pay.vue';
import CoampayV2 from '@/components/plugins/coampay/v2/coam-pay.vue';
import Reappay from "@/components/plugins/reap/reap-pay.vue";
import Wizzpay from "@/components/plugins/wizzpay/wizz-pay.vue";
import Korpay from "@/components/plugins/korpay/korpay.vue";
import DanalTPay from '@/components/plugins/danal/danal-t-pay.vue';
import DanalPPay from '@/components/plugins/danal/danal-p-pay.vue';
import Onflatpay from "@/components/plugins/onflat/onflat-pay.vue";
import Allat from '@/components/plugins/kg/allatpay.vue';
import Settlebank from "@/components/plugins/hecto-financial/settlebank/settlebank.vue";
import Paynb from "@/components/plugins/paynb/paynb.vue";
import Paytus from '@/components/plugins/paytus/paytus-pay.vue';
import Mainpay from "@/components/plugins/main/main-pay.vue";
import Smartro from "@/components/plugins/smartro/smartro.vue";
import Wayup from "@/components/plugins/wayup/wayup-pay.vue"
import Payletter from "@/components/plugins/payletter/payletter.vue";
import Toss from "@/components/plugins/toss/tosspayments.vue";
import Thepayone from "@/components/plugins/thepayone/thepayone.vue";

export default {
    components: {
        Billgate,
        Coampay,
        CoampayV2,
        Lumenpay,
        Reappay,
        Wizzpay,
        Korpay,
        DanalTPay,
        DanalPPay,
        Onflatpay,
        Allat,
        Settlebank,
        Paynb,
        Paytus,
        Mainpay,
        Smartro,
        Wayup,
        Payletter,
        Toss,
        Thepayone
    },
    computed: {
        pg(){
            switch(this.order?.paymentGateway){
                case "danal": {
                    switch(this.order?.paymentMethod){
                        case "creditcard": {
                            return "danal-t-pay";
                        }
                        case "mobile": {
                            return "danal-p-pay";
                        }
                    }
                }
                case "hecto-financial": {
                    return "settlebank";
                }
            }

            return this.order.paymentGateway;
        }
    },
    data(){
        return { order: null };
    },
    methods: {
        pay(){
            this.$nextTick(() => {
                this.$refs?.pg?.pay?.();
            })
        },
        complete(payload){
            this.$emit("complete", payload);
        }
    }
}
</script>